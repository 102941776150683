export const GET_CLIENT = `
  query GetClient {
    getClient {
      id
      email
      full_name
    }
  }
`;

export const GET_PROPERTY_LIST = `
  query getPropertyList{
    getPropertyList{
      id
      name
      description
      bundle_id
    }
  }
`;

export const GET_PROPERTY_VIEW = `
  query getPropertyView($id: String!) {
    getPropertyView(id: $id)
  }
`;

export const GET_USER = `
  query getUser($id: String!) {
    getUser(id: $id) {
      id
      email
      full_name
      lock
      tel
    }
  }
`;

export const GET_USER_PROPERTY_BY_PROPERTY_ID = `
  query getUserPropertyNyPropertyId($property_id: String!) {
    getUserPropertyByPropertyId(property_id: $property_id) {
      id
      user_id
      property_id
      bundle_data
    }
  }
`;

export const GET_ME_DRAW = `
  query GetMeDraw {
    meDraw {
      id
      name
      email
      role
      temporary
      temporary_date
      company {
        id
        name
        company_type
      }
    }
  }
`;

export const GET_DRAW_BY_ID = `
  query getDrawById($drawId: String!) {
    getDrawById (drawId: $drawId) {
      id
      name
    }
  }
`;