import React from 'react';
import '../../assets/scss/custom_material.scss';
import CLogin from "../../ccontainers/cbasic/clogin";
import CRegister from "../../ccontainers/cbasic/cregister";
import CResetPassword from "../../ccontainers/cbasic/creset-password";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {TEXT_LIST} from "../../constants/index";

class User extends React.Component {
  componentDidMount() {
    this.props.onInit();
  }
  
  render() {
    const {user_info, user_action, me, getUser} = this.props;
    let result = '';
    
    if (me && me.id && user_action !== 'edit') {
      if (!user_info.id) {
        getUser();
      }
      return this.Profile();
    }
    else if (user_action === 'sign_in') {
      result = <CLogin/>
    }
    else if (user_action === 'register' || user_action === 'edit') {
      result = <CRegister/>
    }
    else if (user_action === 'forgot_password' || user_action === 'reset_password') {
      result = <CResetPassword/>
    }
    else {
      result = <p> Not found action </p>;
    }
    
    return <div className="user">{result}</div>
  }
  
  Profile() {
    const {user_info, onChangeLogout, onChangeEdit, onChangeCloseUser} = this.props;
    return (
      <div>
        <div className="w-100 text-right p-2">
          <Button variant="outlined" size="small" color="error"
                  onClick={() => onChangeLogout()}>{TEXT_LIST.logout}</Button>
        </div>
        <Card sx={{maxWidth: 500, margin: 'auto'}}>
          <CardContent>
            <Typography variant="h5" component="div" className="text-main">
              {user_info.full_name}
            </Typography>
            <br/>
            <Typography sx={{fontSize: 16, lineHeight: 1.8}}>
              <label>Email: {user_info.email}</label> <br/>
              <label>Tel: {user_info.tel}</label>
            </Typography>
          </CardContent>
          <CardActions className="float-right">
            <Button className="text-main" onClick={() => onChangeEdit()}>
              {TEXT_LIST.edit} <EditIcon fontSize="small"/>
            </Button>
            <Button className="text-danger" onClick={() => onChangeCloseUser()}>
              {TEXT_LIST.close} <CloseIcon fontSize="small"/>
            </Button>
          </CardActions>
        </Card>
      
      </div>
    );
  }
}

export default User;