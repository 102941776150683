import React from 'react';
import '../../assets/scss/shapespark/shapespark.scss';
import '../../assets/scss/shapespark/webwalk.scss';
import {withRouter} from "react-router-dom";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import KeyIcon from '@mui/icons-material/Key';
import {TEXT_LIST} from "../../constants/index";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {email: '', password: ''};
  }
  
  render() {
    const {onChangeAction, onChangeSignInClient, onChangeCloseUser} = this.props;
    return (
      <div className="user-action">
        <div className="parent">
          <div className="header">
            <h4 className="w-100">{TEXT_LIST.main}</h4>
          </div>
          <div className="body">
            <FormControl fullWidth variant="standard">
              <InputLabel>{TEXT_LIST.email_address}</InputLabel>
              <Input
                onChange={(e) => this.setState({email: e.target.value})}
                startAdornment={<MailOutlineIcon position="start" fontSize="medium"/>}
              />
            </FormControl>
            
            <br/>
            <br/>
            
            <FormControl fullWidth variant="standard">
              <InputLabel>{TEXT_LIST.password}</InputLabel>
              <Input
                onChange={(e) => this.setState({password: e.target.value})}
                type={'password'}
                startAdornment={<KeyIcon position="start" fontSize="medium"/>}
              />
            </FormControl>
            
            <br/>
            <br/>
            
            <div className="footer text-main">
              <span className="font-weight-bold"
                    onClick={() => onChangeSignInClient(this.props.history, this.state)}>{TEXT_LIST.login} </span>
              |
              <span className="text-danger" onClick={() => onChangeCloseUser()}> {TEXT_LIST.close}</span>
              <br/><br/>
              <span onClick={() => onChangeAction('forgot_password')}>{TEXT_LIST.forgot_password} </span>
              |
              <span onClick={() => onChangeAction('register')}> {TEXT_LIST.register_mode}</span>
            </div>
          </div>
        
        </div>
      </div>
    );
  }
}

export default withRouter(Login);