import {connect} from "react-redux";
import Register from "../../containers/basic/register";
import {user_set_action, user_set_info} from "../../actions/user";
import {TEXT_LIST} from "../../constants/index";
import {basic_set_open_snackbar} from "../../actions/basic";
import {createClient, getErrorMessage, validateEmail, validatePassword, validateTel} from "../../constants/utils";
import {REGISTER_USER} from "../../share/mutation";
import {GET_USER} from "../../share/query";

let info = {};
let me = {};
let user_action = '';
let int_user_id = '';

const mapStateToProps = (state) => {
  user_action = state.user.action;
  me = state.basic.me;
  
  return {
    user_info: state.user.info,
    user_action: state.user.action,
    me: state.basic.me,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: (ad_id) => {
      int_user_id = ad_id;
      
      if(user_action === 'edit') {
        const client = createClient();
        client.request(GET_USER, {id: me.id}).then(data => {
          info = data.getUser;
          info.mode = 'EDIT';
          dispatch(user_set_info(info));
        }).catch(e => {
          const error = getErrorMessage(e);
          dispatch(basic_set_open_snackbar({type: 'error', message: error}));
        })
      } else {
        info = {mode: 'ADD', id: '', full_name: '', email: '', password: '', confirm_password: '', tel: '', lock: false};
        dispatch(user_set_info(info));
      }
    },
    onChangeField: (name, value) => {
      info[name] = value;
      dispatch(user_set_info(info));
    },
    onChangeAction: (action) => {
      dispatch(user_set_action(action));
    },
    onChangeClose: () => {
      dispatch(user_set_action('me'));
    },
    onChangeRegister: () => {
      const errors = checkData(info);
      if (errors.length) {
        return dispatch(basic_set_open_snackbar({type: 'error', message: errors.join('\n')}))
      }
      else {
        const client = createClient();
        let variables = info;
        variables.int_user_id = int_user_id;
   
        client.request(REGISTER_USER, variables).then(data => {
          dispatch(user_set_action('sign_in'));
          dispatch(user_set_info({}));
          
          if (info.mode === 'ADD'){
            dispatch(basic_set_open_snackbar({message: '登録されました。ログインして下さい'}));
          }
          else if(info.mode === 'EDIT'){
            dispatch(basic_set_open_snackbar({message: TEXT_LIST.has_updated}));
          }
          
        }).catch(e => {
          const error = getErrorMessage(e);
          dispatch(basic_set_open_snackbar({type: 'error', message: error}));
        })
      }
    },
  }
};

const checkData = (info) => {
  let msg = [];
  if (!info.full_name) msg.push(`-「${TEXT_LIST.full_name}」${TEXT_LIST.is_required}`);
  if (!info.email) msg.push(`-「${TEXT_LIST.email_address}」${TEXT_LIST.is_required}`);
  if (!info.tel) msg.push(`-「${TEXT_LIST.tel}」${TEXT_LIST.is_required}`);
  if (info.mode === 'ADD') {
    if (!info.password) msg.push(`-「${TEXT_LIST.password}」${TEXT_LIST.is_required}`);
    if (!info.confirm_password) msg.push(`-「${TEXT_LIST.confirm_password}」${TEXT_LIST.is_required}`);
  }
  
  if (info.email && !validateEmail(info.email)) msg.push(`-「${TEXT_LIST.email_address}」の項目が必須です`);
  if (info.tel && validateTel(info.tel)) {
    msg.push(`- 「${TEXT_LIST.tel}」${validateTel(info.tel)}`);
  }
  if (info.password) {
    if (validatePassword(info.password)) msg.push(`-${validatePassword(info.password)}`);
    if (info.password !== info.confirm_password) msg.push('-「パスワード」と確認用の「確認パスワード」が違います。改めてご入力下さい');
  }
  
  return msg;
};

const CRegister = connect(mapStateToProps, mapDispatchToProps)(Register);

export default CRegister;