import {combineReducers} from "redux";
import {invoice_info} from "./invoice_info";
import property from "./property/index";
import basic from "./basic/index";
import user from "./user/index";

const reducer = combineReducers({
  invoice_info: invoice_info,
  property,
  basic,
  user
});

export default reducer;
