import {combineReducers} from "redux";
import {loading} from "./loading";
import {snackbar} from "./snackbar";
import {me} from "./me";
import {draw_info} from "./draw";

const basic = combineReducers({
  loading,
  snackbar,
  me,
  draw_info
});
export default basic;