import React, {Fragment} from "react";
import {Router, Route, Switch} from "react-router-dom"
import CInvoices from "../../ccontainers/cpages/cinvoices";
import CPageContainer from "../../ccontainers/cbasic/cpage-container";
import CProperty from "../../ccontainers/cpages/cproperty";
import CPropertyList from "../../ccontainers/cpages/cproperty-list";
import history from './history';

const Pages = () => {
  return (
    <Fragment>
      <Router history={history}>
        <Switch>
          <CPageContainer>
            <Route exact path="/" component={null}/>
            <Route exact path="/properties" component={null}/>
            <Route exact path="/:int_user_id/properties/:id" component={CProperty}/>
            <Route exact path="/:int_user_id/properties/:id/:action" component={CProperty}/>
            <Route exact path="/:int_user_id/properties/:id/draw/:draw_id/id/:u_id/:action" component={CProperty}/>
            <Route exact path="/properties/:id/:action" component={CProperty}/>
            <Route exact path="/invoices" component={CInvoices}/>
          </CPageContainer>
        </Switch>
      </Router>
    </Fragment>
  )
};
export default Pages;