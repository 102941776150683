import {SHAPESPARK_TYPES, TEXT_LIST} from './index'
import {HOST_ADDR, HOST_ADDR_PCHAT} from "./setting";
import {GraphQLClient} from "graphql-request";
import {GET_CLIENT} from "../share/query";
import {basic_set_me, basic_set_open_snackbar} from "../actions/basic";
import {user_set_action} from "../actions/user";
import moment from 'moment';
import { SIGN_IN_CLIENT_WITH_DRAW } from '../share/mutation';

const token_name = 'sp-client-token';

export const getTitleSPByType = (type) => {
  const shape_spark = SHAPESPARK_TYPES.find(s => s.type === type);
  return shape_spark ? shape_spark.title : '';
};

export const createClient = () => {
  const client = new GraphQLClient(HOST_ADDR + "/graphql", {
    headers: {
      "sp-client-token": localStorage.getItem(token_name),
      "sp-type": 'client'
    },
  });
  
  return client;
};
export const getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const createClientPchat = () => {
  const client = new GraphQLClient(HOST_ADDR_PCHAT + "/graphql", {
    headers: {
      "x-token": getCookie("x-token"),
      // "user-type": user_type ? user_type : '',
      // Used for localhost
      "dev-cookie": document.cookie,
    },
    credentials: 'include',  // Include cookies in requests
  });
  
  return client;
};

// Get error message from network result
export const getErrorMessage = (error, dispatch) => {
  let error_message = 'エラーが発生しました。もう一度お試しください';
  if (error.response && error.response.errors[0].message) {
    error_message = error.response.errors[0].message;
  }
  
  if (dispatch && error_message.indexOf('session expired') > -1) {
    logout();
    dispatch(user_set_action('sign_in'));
  }
  
  return error_message;
};

// Get info user when currently logged.
export const getMe = async (dispatch) => {
  const client = createClient();
  let me = {};
  await client.request(GET_CLIENT).then(data => {
    dispatch(basic_set_me(data.getClient));
    me = data.getClient;
  }).catch(e => {
    const error = getErrorMessage(e, dispatch);
    dispatch(basic_set_open_snackbar({type: 'error', message: error}));
  });
  
  return me;
};

// Set localStorage when user login.
export const setLogin = async ({token}) => {
  localStorage.setItem(token_name, token);
  return true;
};

// Logout user
export const logout = () => {
  localStorage.removeItem(token_name);
  return true;
};

// Break string
export const addBreak = (string) => {
  let matches = string.split(/\n|\s\n/);
  return matches.join("<br>\n") + "<br>";
};

export const validateEmail = (email) => {
  if (!email || email.trim === '') return false;
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export const validateTel = (tel) => {
  let error_msg = null;
  const malformed = TEXT_LIST.malformed + TEXT_LIST.please_check_again_and_enter;
  if (!tel || tel.length === 0) {
    error_msg = TEXT_LIST.is_required;
  } else if (!validateNumber(tel)) {
    error_msg = malformed;
  } else if (tel.length < 10 || tel.length > 11) {
    error_msg = malformed;
  }
  return error_msg;
};

export const validatePassword = (password) => {
  let error_msg = null;
  if (!password || password.trim() === '') {
    error_msg = '「パスワード」は必須です';
  } else if (password.length < 8) {
    error_msg = '「パスワード」は8文字以上でご入力下さい';
  } else if (password.length > 20) {
    error_msg = '「パスワード」は20文字以内でご入力下さい';
  } else if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)) {
    error_msg = 'パスワードは「半角英大文字・英小文字・数字を含む8文字以上の文字列を指定して下さい。';
  }
  return error_msg;
};

export const validateNumber = (string) => {
  return (/^\d+$/g).test(string);
};

export const convertObjectToString = (Object) => {
  return JSON.stringify(Object);
};

export const convertStringToObject = (String) => {
  return JSON.parse(String);
};

export const getBrowser = () => {
  const test = function(regexp) {return regexp.test(window.navigator.userAgent)};
  switch (true) {
    case test(/edg/i): return "Microsoft Edge";
    case test(/trident/i): return "Microsoft Internet Explorer";
    case test(/firefox|fxios/i): return "Mozilla Firefox";
    case test(/opr\//i): return "Opera";
    case test(/ucbrowser/i): return "UC Browser";
    case test(/samsungbrowser/i): return "Samsung Browser";
    case test(/chrome|chromium|crios/i): return "Google Chrome";
    case test(/safari/i): return "Apple Safari";
    default: return "Other";
  }
};

export const checkExpired = (date) => {
  if (!date) return true;
  
  let end_date = moment(date).format("YYYY-MM-DD");
  end_date = moment(end_date);
  
  let current_date = moment().format("YYYY-MM-DD");
  current_date = moment(current_date);
  
  return current_date > end_date;
};


export const loginWithDraws = async (email,u_id,id,dispatch) => {
  const client = createClient();
  try {
      const signData = await client.request(SIGN_IN_CLIENT_WITH_DRAW, {email, u_id, id});
      const {token} = signData.signInClientWithDraw;
      await setLogin({token});
      const clientNewToken = createClient();
      await clientNewToken.request(GET_CLIENT).then(data => {
        dispatch(basic_set_me(data.getClient));
      })
  } catch (e) {
    const error = getErrorMessage(e, dispatch);
      dispatch(basic_set_open_snackbar({type: 'error', message: error}));
  }
}