export const INVOICE_INFO = "INVOICE_INFO";

export const PROPERTY_SIDEBAR = "PROPERTY_SIDEBAR";
export const PROPERTY_LIST = "PROPERTY_LIST";
export const PROPERTY_INFO = "PROPERTY_INFO";
export const PROPERTY_VIEW = "PROPERTY_VIEW";

export const BASIC_LOADING = "BASIC_LOADING";
export const BASIC_SNACKBAR = "BASIC_SNACKBAR";
export const BASIC_OPEN_SNACKBAR = "BASIC_OPEN_SNACKBAR";
export const BASIC_CLOSE_SNACKBAR = "BASIC_CLOSE_SNACKBAR";
export const BASIC_ME = "BASIC_ME";
export const DRAW_INFO = "DRAW_INFO";

export const USER_ACTION = "USER_ACTION";
export const USER_INFO = "USER_INFO";