import React from 'react';

class Invoices extends React.Component {
  componentDidMount() {
    this.props.onInit();
  }
  
  render() {
    return (
      <p>Invoice Invoice</p>
    );
  }
}
export default Invoices