let const_HOST_ADDR;
let const_WS_HOST_ADDR;
let const_HOST_ADDR_PCHAT;
let const_WS_HOST_PCHAT;

if (process.env.REACT_APP_CURR_ENV === "production"){
  const_HOST_ADDR = process.env.REACT_APP_HOST_ADDR_PRODUCTION;
  const_WS_HOST_ADDR = process.env.REACT_APP_WS_HOST_ADDR_PRODUCTION;
  const_HOST_ADDR_PCHAT = process.env.REACT_APP_HOST_ADDR_PCHAT_PRODUCTION;
  const_WS_HOST_PCHAT = process.env.REACT_APP_WS_HOST_ADDR_PCHAT_PRODUCTION;
}
else if (process.env.REACT_APP_CURR_ENV === "staging") {
  const_HOST_ADDR = process.env.REACT_APP_HOST_ADDR_STAGING;
  const_WS_HOST_ADDR = process.env.REACT_APP_WS_HOST_ADDR_STAGING;
  const_HOST_ADDR_PCHAT = process.env.REACT_APP_HOST_ADDR_PCHAT_STAGING;
  const_WS_HOST_PCHAT = process.env.REACT_APP_WS_HOST_ADDR_PCHAT_STAGING;
}
else {
  const_HOST_ADDR = process.env.REACT_APP_HOST_ADDR_DEVELOPMENT;
  const_WS_HOST_ADDR = process.env.REACT_APP_WS_HOST_ADDR_DEVELOPMENT;
  const_HOST_ADDR_PCHAT = process.env.REACT_APP_HOST_ADDR_PCHAT_DEVELOPMENT;
  const_WS_HOST_PCHAT = process.env.REACT_APP_WS_HOST_ADDR_PCHAT_DEVELOPMENT;
}

export const HOST_ADDR = const_HOST_ADDR;
export const WS_HOST_ADDR = const_WS_HOST_ADDR;
export const HOST_ADDR_PCHAT = const_HOST_ADDR_PCHAT;
export const WS_HOST_ADDR_PCHAT = const_WS_HOST_PCHAT;