import React from 'react';
import '../../assets/scss/shapespark/shapespark.scss';
import '../../assets/scss/shapespark/webwalk.scss';
import CustomAccordion from "../../custom-components/CustomAccordion";
import {withRouter} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import CUser from "../../ccontainers/cpages/cuser";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import {TEXT_LIST} from "../../constants/index";
import Button from '@mui/material/Button';
import {checkExpired} from "../../constants/utils";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';

const date_range_1 = [1, 2, 3, 4, 5, 6];
const date_range_2 = [7, 8, 9, 10, 11, 12];
const date_range_3 = [13, 14, 15, 16, 17, 18];
const date_range_4 = [19, 20, 21, 22, 23, 24];
const date_range_5 = [25, 26, 27, 28, 29, 30, 31, 32];
const column_a_month = 5;

class Property extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: '',
      expanded_child: '',
      schedule_dialog: false
    }
  }
  
  async componentDidMount() {
    const {params} = this.props.match;
    if (params && params.id) {
      
      if(params.draw_id){
        await this.props.loginWithDraw(params.draw_id,params.u_id,params.id)
      }else{
        await this.props.onInit(params.id);
      }
    }
  }
  
  handleChangeExpanded(value) {
    let {expanded} = this.state;
    expanded = expanded === value ? '' : value;
    this.setState({expanded: expanded})
  }
  
  handleChangeExpandedChild(value) {
    let {expanded_child} = this.state;
    expanded_child = expanded_child === value ? '' : value;
    
    this.setState({expanded_child: expanded_child})
  }
  
  handleChangeScheduleDialog() {
    const { schedule_dialog } = this.state;
    this.setState({schedule_dialog: !schedule_dialog});
    this.props.focusIframe();
  }
  
  // async componentDidUpdate(prev) {
  //   const {property_sidebar, property_view} = this.props;
  //   if (Object.keys(prev.property_view).length === 0 && property_sidebar && property_view.id) {
  //     console.log('Did update ===>');
  //     await this.props.onLoadIframe();
  //   }
  // }
  
  render() {
    const {schedule_dialog} = this.state;
    const {property_sidebar, onChangeSidebar, onChangeClose, focusIframe} = this.props;
    
    if (!property_sidebar) return <div/>;
    const {type, action, status} = property_sidebar;
    
    return (
      <div id="shapespark">
        {/*{this.PropertyHeader()}*/}
        
        <main className={'main ' + (property_sidebar.type === 'user' ? 'd-none' : ' ')}>
          {this.PropertyIframe()}
  
          { status ?
            <span className="close-sidebar">
              <CloseIcon onClick={() => onChangeClose(property_sidebar, true)}/>
            </span>
            :
            <span className="show-sidebar">
              <FastRewindIcon onClick={() => onChangeSidebar(property_sidebar, 'property')}/>
            </span>
          }
          
          {/*cursor-no-drop*/}
          <div
            id="sidebar"
            className={'sidebar ' + (action ? '' : 'cursor-no-drop')}
            onClick={() => focusIframe()}
          >
            {status ? this.PropertySidebar() : null}
          </div>
        </main>
        
        {type === 'user' ? (<CUser/>) : null}
  
        {schedule_dialog ? this.ScheduleDialog() : null}
      
      </div>
    );
  }
  
  PropertyHeader() {
    const {onChangeSidebar, property_sidebar} = this.props;
    const {type, status} = property_sidebar;
    
    const tab1_active = type === 'tab1' && status ? ' tab-link-active' : '';
    const tab2_active = type === 'tab2' && status ? ' tab-link-active' : '';
    const tab3_active = type === 'tab3' && status ? ' tab-link-active' : '';
    
    return (
      <header className="header">
        <div className="tabs" id="tabs">
          <button
            className={'tab-link border-right' + tab1_active}
            onClick={() => onChangeSidebar(property_sidebar, 'tab1')}
          >
            プレゼンボード
          </button>
          <button
            className={'tab-link border-right' + tab2_active}
            onClick={() => onChangeSidebar(property_sidebar, 'tab2')}
          >
            カラーセレクト
          </button>
          <button
            className={'tab-link' + tab3_active}
            onClick={() => onChangeSidebar(property_sidebar, 'tab3')}
          >
            プロフィール
          </button>
        </div>
      </header>
    );
  }
  
  PropertyIframe() {
    return (
      <div className="render" id="render">
        {/*<iframe*/}
          {/*allowFullScreen*/}
          {/*id="3d-render"*/}
          {/*className="3d-render"*/}
          {/*src={iframe_url}*/}
          {/*title="prefab 3D"*/}
          {/*allow="xr-spatial-tracking"*/}
        {/*/>*/}
      </div>
    );
  }
  
  PropertySidebar() {
    const {params} = this.props.match;
    const {property_sidebar, property_view} = this.props;
    const {action} = property_sidebar;
    const action_distance = !!(params && params.action && params.action === 'distance');
    
    return (
      <div className={"list-choose-colors " + (action ? '' : 'pointer-events-none')}>
        {/*Go bach to property list*/}
        {/*<Accordion>*/}
          {/*<AccordionSummary*/}
            {/*className="ac-parent"*/}
            {/*onClick={() => onChangePropertyList(this.props.history)}*/}
          {/*>*/}
            {/*<Typography className="ac-parent-title"> 物件一覧 </Typography>*/}
          {/*</AccordionSummary>*/}
        {/*</Accordion>*/}
  
        {/*Bundle data*/}
        {/* {this.Schedule()} */}

        {this.Draw()}
        
        {/*Show material and combos*/}
        {this.MaterialAndCombo()}
  
        {/*Show material and combos*/}
        
        {property_view.extension_existed ? this.Extension() : ''}
        
        {/*Show popup*/}
        {this.Popup()}
        
        {/*Show profile user*/}
        {/* {this.Profile()} */}
  
        {/*Show distance*/}
        {action_distance ? this.Distance() : null}
  
        {/*Show save and export data*/}
        {/* {this.SaveAndExport()} */}
      
      </div>
    );
  }
  
  MaterialAndCombo() {
    const {
      me, property_view, property_sidebar,
      onChangeColor, onChangeCombo, onChangeSave, onChangeSidebar, onChangeAgreed
    } = this.props;
    const {expanded, expanded_child} = this.state;
    const expand = 'material_and_combo';
    
    let {materials, combos, combo_active} = property_view.bundle;
    combos = combos.filter(combo => combo.show);
    
    const ac_not_exist = !combos.length && !materials.length ? 'ac-not-exist' : '';
    
    return (
      <Accordion
        expanded={expanded === expand}
        sx={{margin: 0}}
        className={ac_not_exist}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          onClick={() => this.handleChangeExpanded(expand)}
          className="ac-parent"
        >
          <Typography className="ac-parent-title">
            カラーセレクト
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="sidebar-tab">
            {/*Show combos*/}
            { combos && combos.length > 0 ? (
              <CustomAccordion
                key='combos'
                title='選択セット'
                id='combos'
              >
                {combos.map((combo) => {
                  const active = combo_active && combo_active === combo.id ? ' active' : '';
                  return (
                    <div className={'choose-color' + active} key={combo.id} onClick={() => onChangeCombo(combo)}>
                      <span>{combo.name}</span>
                    </div>
                  )
                })}
              </CustomAccordion>
            ) : null}
  
            {/*Show material*/}
            {materials.length ? materials.map((material) => {
              if (!material.category_group || !material.show) return null;
    
              const {category_group, agreed, saved, end_date} = material;
              const {category_group_details, category_group_detail_active} = category_group;
              const name = material.new_name ? material.new_name : material.name;
              
              const expired = checkExpired(end_date); // Check expired.
              
              return (
                <CustomAccordion
                  key={material.id}
                  id={material.id}
                  expanded={expanded_child === material.id}
                  handleChangeExpandedChild={() => this.handleChangeExpandedChild(material.id)}
                  summaryDetails={
                    <div className="w-100 d-inline-flex" style={{pointerEvents: 'auto'}}>
                      <Typography
                        style={{width: 'calc(100% - 110px)', textAlign: 'left', pointerEvents: 'auto'}}
                        onClick={() => this.handleChangeExpandedChild(material.id)}
                      >
                        {name} <br/>
                        <small
                          className={"selected-material " + (category_group_detail_active ? 'text-primary' : 'text-danger')}>
                          {category_group_detail_active ? '選択済み' : '未選択'}
                        </small>
                      </Typography>
                      <Typography className="text-right" style={{width: '110px'}}>
                        {agreed ? null :
                          (expired ? null :
                            <Button
                              size="small" variant="outlined" color="primary"
                              style={{padding: '0px', minWidth: '50px', margin: '0 5px'}}
                              onClick={() => {
                                (me && me.id ?
                                  onChangeSave(material, category_group_detail_active)
                                  :
                                  onChangeSidebar(property_sidebar, 'user'))
                              }}
                            >
                              保存
                            </Button>
                          )
                        }
            
                        {agreed ?
                          <label className="text-danger">確定済</label>
                          :
                          (expired ? null :
                            <Button
                              size="small" variant="outlined" color="error"
                              style={{padding: '0px', minWidth: '50px', margin: '0'}}
                              disabled = {!(category_group_detail_active && saved)}
                              onClick={() => {
                                me && me.id ?
                                  onChangeAgreed(material.id)
                                  :
                                  onChangeSidebar(property_sidebar, 'user')
                              }}
                            >
                              確定
                            </Button>
                          )
                        }
                      </Typography>
                    </div>
                  }
                >
                  {category_group_details ? category_group_details.map(cgd => {
                    if(cgd.category){
                      const {attachment, name} = cgd.category;
                      const cgd_price = cgd.category_group_detail_price || {};
                      const cgd_active = category_group_detail_active && category_group_detail_active === cgd.id ? ' active' : '';
                      return (
                        <div className={'choose-color' + cgd_active} key={cgd.category.id}
                             onClick={() => onChangeColor(material.id, cgd)}>
                          <img alt="" src={attachment.file_url}/>
                          <span className="pt-2 w-100">{name}</span>
                          <span className="pt-2 w-100">{TEXT_LIST.price}: {cgd_price.price || ''}</span>
                        </div>
                      )
                    }
                    
                  }) : null}
                </CustomAccordion>
              )
            }) : null}
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
  
  Extension() {
    const {
      property_view, onChangeExtension
    } = this.props;
    
    let {extensions} = property_view.bundle;
    
    const {expanded} = this.state;
    const expand = 'extension';
    const ac_not_exist = !extensions.length? 'ac-not-exist' : '';
    
    return (
      <Accordion
        expanded={expanded === expand}
        sx={{margin: 0}}
        className={ac_not_exist}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          onClick={() => this.handleChangeExpanded(expand)}
          className="ac-parent"
        >
          <Typography className="ac-parent-title">
            設備セレクト
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="sidebar-tab">
            {extensions.map((extension) => {
              const {name, new_name, node_types, node_type_active} = extension;
              const extension_name = new_name ? new_name : name;
              
              return (
                <CustomAccordion
                  key={extension.id}
                  title={extension_name}
                  id={extension.id}
                  summaryDetails={
                    <div className="w-100 d-inline-flex">
                      <Typography
                        style={{textAlign: 'left'}}
                        onClick={() => this.handleChangeExpandedChild(extension.id)}
                      >
                        {extension_name} <br/>
                        <small
                          className={"selected-material " + (node_type_active ? 'text-primary' : 'text-danger')}>
                          {node_type_active ? '選択済み' : '未選択'}
                        </small>
                      </Typography>
                    </div>
                  }
                >
                  {node_types ? node_types.map(node_type => {
                    const active = node_type_active && node_type_active === node_type.name ? ' active' : '';
                    const node_type_name = node_type.new_name ? node_type.new_name : node_type.name;
                    return (
                      <div className={'choose-color' + active} key={node_type_name}
                           onClick={() => onChangeExtension(extension.id, node_type.name)}>
                        <span>{node_type_name}</span>
                      </div>
                    )
                  }) : null}
                </CustomAccordion>
              )
            })}
          
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
  
  Popup() {
    const {expanded} = this.state;
    const {onChangeDialog, property_view} = this.props;
    const {present_boards } = property_view;
    const expand = 'popup';
    const ac_not_exist = !present_boards.length? 'ac-not-exist' : '';
    
    return (
      <Accordion expanded={expanded === expand} className={ac_not_exist}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          className="ac-parent"
          onClick={() => this.handleChangeExpanded(expand)}
        >
          <Typography className="ac-parent-title"> プレゼンボード</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="sidebar-tab">
            {present_boards.map((present_board) => {
              const {id, active, attachment} = present_board;
              if (!attachment) return null;
              return (
                <button
                  key={id}
                  className={'btn-popup ' + (active ? 'btn-popup-active' : '')}
                  onClick={() => onChangeDialog(id)}
                >
                  {present_board.name}
                </button>
              )
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
  
  Profile() {
    const {property_sidebar, onChangeSidebar} = this.props;
    const {expanded} = this.state;
    const expand = 'profile';
    
    return (
      <Accordion expanded={expanded === expand}>
        <AccordionSummary
          className="ac-parent"
          onClick={() => onChangeSidebar(property_sidebar, 'user')}
        >
          <Typography className="ac-parent-title"> ログイン画面 </Typography>
        </AccordionSummary>
      </Accordion>
    )
  }
  
  Distance() {
    const {property_sidebar, onChangeDistance} = this.props;
    const {expanded} = this.state;
    const expand = 'distance';
    const title_no_active = property_sidebar && property_sidebar.distance === 'title_no_active';
    const title_active = property_sidebar && property_sidebar.distance === 'title_active';
    
    return (
      <Accordion expanded={expanded === expand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          className="ac-parent"
          onClick={() => this.handleChangeExpanded(expand)}
        >
          <Typography className="ac-parent-title">計測</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{borderTop: '1px solid white', padding: '8px !important'}}>
          <div className="btn-action text-right" style={{height: '25px'}}>
            <div
              className='btn btn-distance content-center border-right distance-active opacity-75'
              onClick={() => onChangeDistance('title_active')}
              style={title_active ? {opacity: '0.3'} : {}}
            >
              測定開始
            </div>
            <div
              className='btn btn-distance content-center border-right distance-no-active opacity-75'
              onClick={() => onChangeDistance('title_no_active')}
              style={title_no_active ? {opacity: '0.3'} : {}}
            >
              測定終了
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
  
  SaveAndExport() {
    const {me, property_sidebar, onChangeExport, onChangeSidebar} = this.props;
    const {expanded} = this.state;
    const expand = 'save_and_export';
    
    return (
      <Accordion expanded={expanded === expand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          className="ac-parent border-0"
          onClick={() => this.handleChangeExpanded(expand)}
        >
          <Typography className="ac-parent-title"> 入力内容を出力 </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{borderTop: '1px solid white', padding:'8px !important'}}>
          <div className="btn-action">
            {/*<div*/}
              {/*className='btn btn-save content-center border-right'*/}
              {/*onClick={() => { me && me.id ? onChangeSave() : onChangeSidebar(property_sidebar, 'user')}}*/}
            {/*>*/}
              {/*データ保存*/}
            {/*</div>*/}
            <div
              className='btn btn-export content-center'
              onClick={() => {me && me.id ? onChangeExport() : onChangeSidebar(property_sidebar, 'user')}}
            >
              ファイルを書き出す
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
  
  Schedule() {
    const {expanded} = this.state;
    const expand = 'schedule';
    
    return (
      <Accordion expanded={expanded === expand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          onClick={() => this.handleChangeExpanded(expand)}
          className="ac-parent"
        >
          <Typography className="ac-parent-title text-danger">仕様決めスケジュール</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="sidebar-tab ">
            <Typography className="pt-2">現在のステータス[ ? ]: 素材選択</Typography>
            <Typography className="pt-2 text-danger">提出締切日: 2022年12月3日</Typography>
            <small className="pt-3">※ 締切日までに選択内容を提出してください</small>
            <div className="btn-action d-contents">
              <Button className="m-3 btn" size="small" onClick={() => this.handleChangeScheduleDialog()}>
                現在の選択内容で提出
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
  
  ScheduleDialog() {
    const {schedule_dialog} = this.state;
    const {property_view} = this.props;
    if (!property_view || !property_view.bundle) return;
  
    let {spec_overviews} = property_view;
    let {materials, extensions} = property_view.bundle;
    let dates = [];
    //Sort date for materials, extensions, spec_overviews
    materials = materials.sort((a, b) => moment(a.end_date).diff(moment(b.end_date)));
    spec_overviews = spec_overviews.sort((a, b) => moment(a.end_date).diff(moment(b.end_date)));
    extensions = extensions.sort((a, b) => moment(a.end_date).diff(moment(b.end_date)));
    
    //Map end date.
    materials.forEach((material) => {
      if (material.end_date) dates.push(moment(material.end_date));
    });
    spec_overviews.forEach((spec_overview) => {
      if (spec_overview.start_date) dates.push(moment(spec_overview.start_date));
      if (spec_overview.end_date) dates.push(moment(spec_overview.end_date));
    });
    extensions.forEach((extension) => {
      if (extension.end_date) dates.push(moment(extension.end_date));
    });
    
    //Min end date
    const min_date = moment.min(dates);
    const min_year = min_date.year();
    const min_month = min_date.month() + 1;
    
  
    //Max end date
    const max_date = moment.max(dates);
    const max_year = max_date.year();
    const max_month = max_date.month() + 1;
    
    //Create year and month
    let year_ranges = [];
    //EX 2022 !== 2023
    if (min_year !== max_year) {
      for (let y = min_year; y <= max_year; y++) {
      
        let year = {year: y, months: []};
  
        if (y === max_year) {
          for (let m = 1; m <= max_month; m++) {
            year.months.push(m);
          }
        } else if (y > min_year && y < max_year) {
          for (let m = 1; m <= 12; m++) {
            year.months.push(m);
          }
        } else {
          for (let m = min_month; m <= 12; m++) {
            year.months.push(m);
          }
        }
      
        year_ranges.push(year);
      }
    } //EX 2022 === 2022
    else {
      let year = {year: min_year, months: []};
      for (let m = min_month; m <= max_month; m++) {
        year.months.push(m);
      }
      year_ranges.push(year);
    }
    
    const current = moment().format('YYYY-MM-DD');
    let number_column = 0;
    
    return (
      <div>
        <Dialog
          id="schedule_dialog"
          fullScreen
          open={schedule_dialog}
          onClose={() => this.handleChangeScheduleDialog()}
        >
          <DialogTitle>{"仕様決めスケジュール"}</DialogTitle>
          <DialogContent>
            <table className="table table-bordered">
              <thead>
              <tr className="fs-14px">
                <th id="th-line" rowSpan={3} className="p-0" width={200} height={88} style={{minWidth: '140px', maxWidth: '250px'}}>
                  <div className="div-line">
                    <div className="month">月</div>
                    <div className="process">工程</div>
                    <div className="line" id="line" style={{position: 'absolute'}}/>
                  </div>
                </th>
                {year_ranges.map(year_range => {
                  const {year, months} = year_range;
                  const col_span = months.length * column_a_month;
                  number_column += col_span;
                  
                  return (
                    <th key={year} colSpan={col_span}>{year}年</th>
                  )
                })}
              </tr>
              <tr className="fs-14px">
                {year_ranges.map(year_range => {
                  const {months} = year_range;
                  return (
                    months.map(month => {
                      return <th key={year_range + "/" + month} colSpan={column_a_month}>{month}月</th>
                    })
                  )
                })}
              </tr>
              <tr className="fs-14px">
                {[...new Array(number_column).keys()].map(column => {
                  return ( <th key={column} height="42px"/>)
                })}
              </tr>
              </thead>
              <tbody className="text-center">
              
              {/*spec_overviews schedule*/}
              {spec_overviews.map(spec_overview => {
                let {name, start_date, end_date, id} = spec_overview;
                let run = false;
  
                end_date = this.formatDate(end_date).date;
                const end_date_day = this.formatDate(end_date).get_day;
                const end_date_month = this.formatDate(end_date).get_month;
                const end_date_year = this.formatDate(end_date).get_year;
  
                start_date = this.formatDate(start_date).date;
                const start_date_day =this.formatDate(start_date).get_day;
                const start_date_month = this.formatDate(start_date).get_month;
                const start_date_year = this.formatDate(start_date).get_year;
  
                const end_range_column = this.DateRangeColumn(end_date_day);
                const start_range_column = this.DateRangeColumn(start_date_day);
  
                return (
                  <tr key={id}>
                    <td className="fs-14px">{name}</td>
                    {!end_date ? (
                      [...new Array(number_column).keys()].map(column => {
                        return ( <td key={column}/>)
                      })
                    ) : (
                      year_ranges.map(year_range => {
                        const {months, year} = year_range;
          
                        return (
                          months.map(month => {
                            return (
                              [1, 2, 3, 4, 5].map((column) => {
                                const show_end_date = end_date_month === month && end_date_year === year && end_range_column === column ? end_date.format('MM/DD') : null;
                                const show_start_date = start_date_month === month && start_date_year === year && start_range_column === column ? start_date.format('MM/DD') : null;
                  
                                if (show_start_date) {
                                  run = true;
                                  return (
                                    <td width="42px" key={column} style={{maxWidth: '42px', backgroundColor: '#e7e6e6'}}/>
                                  )
                                } else if (show_end_date) {
                                  run = false;
                                  return (
                                    <td width="42px" key={column} style={{maxWidth: '42px', backgroundColor: '#e7e6e6'}}>
                                      {show_end_date}
                                    </td>
                                  )
                                } else if (run) {
                                  return (
                                    <td width="42px" key={column} style={{maxWidth: '42px', backgroundColor: '#e7e6e6'}}/>
                                  )
                                } else {
                                  return (
                                    <td width="42px" key={column} style={{maxWidth: '42px'}}/>
                                  )
                                }
                              })
                            )
                          })
                        )
                      })
                    )}
                  </tr>
                )
              })}
              
              <tr>
                <td className="fs-14px font-weight-bold">仕様を決める項目</td>
                {[...new Array(number_column).keys()].map(column => {
                  return ( <td key={column}/>)
                })}
              </tr>
              {/*materials schedule*/}
              {materials.map(material => {
                const {name, new_name, end_date, id, agreed} = material;
                const set_name = new_name || name;
                const {date, get_day, get_month, get_year} = this.formatDate(end_date);
  
                //Check about to expire;
                let about_to_expire = false;
                const about_to_expire_range = [moment(end_date).add(-1, 'days').format('YYYY-MM-DD'), end_date];
  
                if (end_date && about_to_expire_range.includes(current)) {
                  about_to_expire = true;
                }
                
                return (
                  <tr key={id}>
                    <td className="fs-14px">{set_name}</td>
                    {!end_date ? (
                      [...new Array(number_column).keys()].map(column => {
                        return ( <td key={column}/>)
                      })
                    ) : (
                      year_ranges.map(year_range => {
                        const {months, year} = year_range;
                        const range_column = this.DateRangeColumn(get_day);
  
                        return (
                          months.map(month => {
  
                            return (
                              [1, 2, 3, 4, 5].map((column) => {
                                let show_end_date = get_month === month && get_year === year && range_column === column ? date.format('MM/DD') : null;
      
                                if (show_end_date) {
                                  return (
                                    <td
                                      width="42px" key={column}
                                      style={{
                                        maxWidth: '42px',
                                        backgroundColor: !agreed && about_to_expire ? '#fce4d6' : '#e7e6e6'
                                      }}
                                    >
                                      {agreed ?
                                        <div className="w-100">
                                          <div className="agreed"><span className="text-danger"> 済 </span></div>
                                        </div>
                                        : null}
                                      {show_end_date}
                                    </td>
                                  )
                                } else {
                                  return (
                                    <td width="42px" key={column} style={{maxWidth: '42px'}}/>
                                  )
                                }
                              })
                            )
                          })
                        )
                      })
                    )}
                  </tr>
                )
              })}

              {/*extensions schedule*/}
              <tr>
                <td className="fs-14px font-weight-bold">設備設定</td>
                {[...new Array(number_column).keys()].map(column => {
                  return ( <td key={column}/>)
                })}
              </tr>
              {extensions.map(extension => {
                const {name, new_name, end_date, id, agreed} = extension;
                const set_name = new_name || name;
                const {date, get_day, get_month, get_year} = this.formatDate(end_date);
                //Check about to expire;
                let about_to_expire = false;
                const about_to_expire_range = [moment(end_date).add(-1, 'days').format('YYYY-MM-DD'), end_date];
  
                if (end_date && about_to_expire_range.includes(current)) {
                  about_to_expire = true;
                }
  
                return (
                  <tr key={id}>
                    <td className="fs-14px">{set_name}</td>
                    {!end_date ? (
                      [...new Array(number_column).keys()].map(column => {
                        return ( <td key={column}/>)
                      })
                    ) : (
                      year_ranges.map(year_range => {
                        const {months, year} = year_range;
                        const range_column = this.DateRangeColumn(get_day);
          
                        return (
                          months.map(month => {
              
                            return (
                              [1, 2, 3, 4, 5].map((column) => {
                                let show_end_date = get_month === month && get_year === year && range_column === column ? date.format('MM/DD') : null;
                  
                                if (show_end_date) {
                                  return (
                                    <td
                                      width="42px" key={column}
                                      style={{
                                        maxWidth: '42px',
                                        backgroundColor: !agreed && about_to_expire ? '#fce4d6' : '#e7e6e6'
                                      }}
                                    >
                                      {agreed ?
                                        <div className="w-100">
                                          <div className="agreed"><span className="text-danger"> 済 </span></div>
                                        </div>
                                        : null}
                                      {show_end_date}
                                    </td>
                                  )
                                } else {
                                  return (
                                    <td width="42px" key={column} style={{maxWidth: '42px'}}/>
                                  )
                                }
                              })
                            )
                          })
                        )
                      })
                    )}
                  </tr>
                )
              })}
              </tbody>
            </table>
            <div className="d-none">{setTimeout(() => {this.AddLineStyle()}, 800)}</div>
            
          </DialogContent>
          <DialogActions className="p-4 m-auto">
            <Button variant="outlined" onClick={() => this.handleChangeScheduleDialog()}>{TEXT_LIST.close}</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
  
  AddLineStyle() {
    const th_line = document.getElementById('th-line');
    if (th_line) {
      const x = th_line.offsetWidth;
      const y = th_line.offsetHeight;
      const rotate = (Math.atan2(y, x) * 180 / Math.PI) - 0.1;
      const width = Math.ceil(Math.sqrt((x * x) + ( y * y)));
      
      let line = document.getElementById('line');
      line.style.width = `${width}px`;
      line.style.borderBottom = '1px solid #0000009c';
      line.style.transformOrigin = 'left';
      line.style.transform = `rotate(${rotate}deg)`;
    }
    
    return (<></>)
  }
  
  DateRangeColumn(date) {
    let range_column = 0;
    
    if (date_range_1.includes(date)) {
      range_column = 1;
    } else if (date_range_2.includes(date)) {
      range_column = 2;
    } else if (date_range_3.includes(date)) {
      range_column = 3;
    } else if (date_range_4.includes(date)) {
      range_column = 4;
    } else if (date_range_5.includes(date)) {
      range_column = 5;
    }
    return range_column;
  }
  
  formatDate(date) {
    date = date ? moment(date) : null;
    const get_day = date ? date.date() : null;
    const get_month = date ? date.month() + 1 : null;
    const get_year = date ? date.year() : null;
    return {date, get_day, get_month, get_year};
  }

  Draw() {
    const {draw} = this.props;
    return (
      <>
      {
        draw.name && 
        <div className="cg-btn-active">
          <Typography className="">{draw.name}</Typography>
        </div>
      }
      </>
     
    )
  }
}

export default withRouter(Property);