import {PROPERTY_SIDEBAR} from "../../constants/store";

const state_default = {status: false, type: '', action: false};
export const property_sidebar = (state = state_default, action) => {
  switch (action.type) {
    case PROPERTY_SIDEBAR:
      return Object.assign({}, action.payload);
    default:
      return state
  }
};