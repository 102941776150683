import {connect} from "react-redux";
import PageContainer from "../../containers/basic/page-container";
import {getMe} from "../../constants/utils";

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: async () => {
        await getMe(dispatch);
    }
  }
};

const CPageContainer = connect(mapStateToProps, mapDispatchToProps)(PageContainer);

export default CPageContainer;