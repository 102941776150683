import React from 'react';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    // style={{padding: '0 8px'}}
    expandIcon={
      <ArrowForwardIosSharpIcon
        style={{color:'rgba(0, 0, 0, 0.6)'}}
        sx={{fontSize: '0.9rem'}}
      />}
    {...props}
  />
))(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(1),
  display: 'flow-root',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

class CustomAccordion extends React.Component {
  render() {
    const {title, children, id, summaryDetails, handleChangeExpandedChild, expanded} = this.props;
    
    if (summaryDetails) {
      return (
        <Accordion className="accordion-custom-root" expanded={expanded}>
          <AccordionSummary
            aria-controls={title || ''} id={id}
            expandIcon={
              <ArrowForwardIosSharpIcon
                onClick={() => handleChangeExpandedChild()}
                style={{color: 'rgba(0, 0, 0, 0.6)'}}
                sx={{fontSize: '0.9rem', pointerEvents: 'auto'}}
              />
            }
          >
            {summaryDetails}
          </AccordionSummary>
  
          {children ? <AccordionDetails>{children}</AccordionDetails> : ''}
          
        </Accordion>
      )
    }
    else {
      return (
        <Accordion className="accordion-custom-root">
          <AccordionSummary aria-controls={title || ''} id={id}>
            <Typography>{title || ''}</Typography>
          </AccordionSummary>
  
          {children ? <AccordionDetails>{children}</AccordionDetails> : ''}
        </Accordion>
      )
    }
  }
}

export default CustomAccordion;