import React, {Fragment} from 'react';
import CLoading from "../../ccontainers/cbasic/cloading";
import CSnackbar from "../../ccontainers/cbasic/csnackbar";
class PageContainer extends React.Component {
  componentDidMount(){
    this.props.onInit();
  }
  
  render() {
    return (
      <Fragment>
        <CSnackbar/>
        <CLoading/>
        {this.props.children}
      </Fragment>
    );
  }
}
export default PageContainer;