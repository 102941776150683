import {connect} from "react-redux";
import PropertyList from "../../containers/pages/property-list";
import {createClient, getErrorMessage} from "../../constants/utils";
import {GET_PROPERTY_LIST} from "../../share/query";
import {property_set_list, property_set_view} from "../../actions/property";
import {basic_set_open_snackbar} from "../../actions/basic";

const mapStateToProps = (state) => {
  return {
    property_list: state.property.list
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => {
      const client = createClient();
      client.request(GET_PROPERTY_LIST).then(data => {
        dispatch(property_set_list(data.getPropertyList));
      }).catch(e => {
        const msg = getErrorMessage(e);
        return dispatch(basic_set_open_snackbar({type: 'error', message: msg}))
      });
      
      dispatch(property_set_view({}));
    },
    onChangeProperty: (history, id) => {
      window.close();
      window.open( '/properties/' + id, '_blank');
      // return history.push('/properties/' + id);
    },
    onChangeActionProperty: (history, id) => {
      window.close();
      window.open( `/properties/${id}/distance`, '_blank');
      // return history.push('/properties/' + id);
    }
  }
};

const CPropertyList = connect(mapStateToProps, mapDispatchToProps)(PropertyList);


export default CPropertyList;