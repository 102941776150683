// mode = ADD, EDIT, DELETE,
export const REGISTER_USER = `
  mutation RegisterUser ($mode: String!, $id: String, $full_name: String!, $email: String!, $password: String, $tel: String, $lock: Boolean, $int_user_id: String!)
  {
    registerUser(mode: $mode, id: $id, full_name: $full_name, email: $email, password: $password, tel: $tel, lock: $lock, int_user_id: $int_user_id){
      id
    }
  }
`;

export const SIGN_IN_CLIENT = `
  mutation signInClient($email: String!, $password: String!) {
    signInClient(email: $email, password: $password){
      token
    }
  }
`;

export const FORGOT_PASSWORD = `
  mutation ForgotPassword ($email: String!) {
    forgotPassword(email: $email){
      email
    }
  }
`;

export const RESET_PASSWORD = `
  mutation ResetPassword ($email: String!, $password: String!, $password_code: String!) {
    resetPassword(email: $email, password: $password, password_code: $password_code){
      id
    }
  }
`;

export const REGISTER_USER_PROPERTY = `
  mutation RegisterUserProperty ($userPropertyInput: UserPropertyInput!) {
    registerUserProperty(userPropertyInput: $userPropertyInput){
      id
    }
  }
`;

export const UPDATE_AGREED_USER_PROPERTY = `
  mutation UpdateAgreedUserProperty ($id: String!, $material_id: String!) {
    updateAgreedUserProperty(id: $id, material_id: $material_id)
  }
`;
export const SIGN_IN_CLIENT_WITH_DRAW = `
  mutation signInClientWithDraw($email: String!, $u_id: String!,$id: String!) {
    signInClientWithDraw(email: $email, u_id: $u_id,id: $id){
      token
    }
  }
`;