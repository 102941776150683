import React from 'react';
import {withRouter} from "react-router-dom";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import KeyIcon from '@mui/icons-material/Key';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import {TEXT_LIST} from "../../constants/index";

class SignUp extends React.Component {
  
  componentDidMount() {
    const {params} = this.props.match;
    if (params && params.int_user_id) {
      this.props.onInit(params.int_user_id);
    }
  }
  
  render() {
    const {user_info, onChangeAction, onChangeField, onChangeRegister, onChangeClose} = this.props;
    
    return (
      <div className="user-action">
        <div className="parent">
          <div className="header">
            <h4 className="w-100">{TEXT_LIST.main}</h4>
          </div>
          <div className="body">
            <h5 className="center">{user_info.mode === 'EDIT' ? TEXT_LIST.edit : TEXT_LIST.register_mode}</h5>
            <br/>
            
            <FormControl fullWidth variant="standard">
              <InputLabel>{TEXT_LIST.full_name}</InputLabel>
              <Input
                startAdornment={<PersonAddAltIcon position="start" fontSize="medium"/>}
                value={user_info.full_name || ''}
                onChange={(e) => onChangeField('full_name', e.target.value)}
              />
            </FormControl>
            
            <br/>
            <br/>
            
            <FormControl fullWidth variant="standard">
              <InputLabel>{TEXT_LIST.email_address}</InputLabel>
              <Input
                startAdornment={<MailOutlineIcon position="start" fontSize="medium"/>}
                value={user_info.email || ''}
                onChange={(e) => onChangeField('email', e.target.value)}
              />
            </FormControl>
            
            <br/>
            <br/>
            
            <FormControl fullWidth variant="standard">
              <InputLabel>{TEXT_LIST.tel}</InputLabel>
              <Input
                startAdornment={<PhoneInTalkIcon position="start" fontSize="medium"/>}
                value={user_info.tel || ''}
                onChange={(e) => onChangeField('tel', e.target.value)}
              />
            </FormControl>
            
            <br/>
            <br/>
  
            {user_info.mode === 'ADD' ? (
              <>
              <FormControl fullWidth variant="standard">
                <InputLabel className="required">{TEXT_LIST.password}</InputLabel>
                <Input
                  type={'password'}
                  startAdornment={<KeyIcon position="start" fontSize="medium"/>}
                  value={user_info.password || ''}
                  onChange={(e) => onChangeField('password', e.target.value)}
                />
              </FormControl>
              <br/><br/>
    
              <FormControl fullWidth variant="standard">
                <InputLabel>{TEXT_LIST.confirm_password}</InputLabel>
                <Input
                  type={'password'}
                  startAdornment={<KeyIcon position="start" fontSize="medium"/>}
                  value={user_info.confirm_password || ''}
                  onChange={(e) => onChangeField('confirm_password', e.target.value)}
                />
              </FormControl>
              <br/><br/>
              </>
            ) : (
              <>
              <FormControl fullWidth variant="standard">
                <InputLabel>{TEXT_LIST.password}</InputLabel>
                <Input
                  type={'password'}
                  startAdornment={<KeyIcon position="start" fontSize="medium"/>}
                  value={user_info.password || ''}
                  onChange={(e) => onChangeField('password', e.target.value)}
                />
              </FormControl>
              <br/><br/>
              {user_info.password ? (
                <>
                  <FormControl fullWidth variant="standard">
                    <InputLabel>{TEXT_LIST.confirm_password}</InputLabel>
                    <Input
                      required
                      type={'password'}
                      startAdornment={<KeyIcon position="start" fontSize="medium"/>}
                      value={user_info.confirm_password || ''}
                      onChange={(e) => onChangeField('confirm_password', e.target.value)}
                    />
                  </FormControl>
                  <br/><br/>
                </>
              ) : null}
              </>
            )}
            
            <div className="footer text-main">
              <span className="font-weight-bold" onClick={() => onChangeRegister()}>
                {user_info.mode === 'EDIT' ? TEXT_LIST.update : TEXT_LIST.register}
                </span>
              <br/>
              <br/>
              {
                user_info.mode === 'EDIT' ? (
                  <span onClick={() => onChangeClose('forgot_password')}>{TEXT_LIST.close} </span>
                ) : (
                  <>
                  <span onClick={() => onChangeAction('forgot_password')}>{TEXT_LIST.forgot_password} </span>
                  |
                  <span onClick={() => onChangeAction('sign_in')}> {TEXT_LIST.login}</span>
                  </>
                )
              }
            </div>
          </div>
        
        </div>
      </div>
    );
  }
}

export default withRouter(SignUp);