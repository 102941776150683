import {connect} from "react-redux";
import User from '../../containers/pages/user'
import {createClient, getErrorMessage, logout} from "../../constants/utils";
import {user_set_action, user_set_info} from "../../actions/user";
import {basic_set_me, basic_set_open_snackbar} from "../../actions/basic";
import {GET_USER} from "../../share/query";
import {property_set_sidebar} from "../../actions/property";

let me = {};
let property_sidebar = {};

const mapStateToProps = (state) => {
  me = state.basic.me;
  property_sidebar = state.property.property_sidebar;
  
  return {
    user_action: state.user.action,
    user_info: state.user.info,
    me: state.basic.me,
    property_sidebar: state.property.property_sidebar
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => {
      if (me && me.id) {
        getUser(dispatch);
        dispatch(user_set_action('me'));
      } else {
        dispatch(user_set_action('sign_in'));
      }
      return true;
    },
    onChangeLogout: () => {
      logout();
      dispatch(basic_set_me({}));
      dispatch(user_set_action('sign_in'));
    },
    onChangeEdit: () => {
      dispatch(user_set_action('edit'));
    },
    onChangeCloseUser: () => {
      property_sidebar.type = 'property';
      dispatch(property_set_sidebar(property_sidebar));
    },
    getUser: () => {
      getUser(dispatch);
    }
  }
};

const getUser = (dispatch) => {
  const client = createClient();
  client.request(GET_USER, {id: me.id}).then(data => {
    dispatch(user_set_info(data.getUser));
  }).catch(e => {
    const error = getErrorMessage(e);
    dispatch(basic_set_open_snackbar({type: 'error', message: error}));
  });
};

const CUser = connect(mapStateToProps, mapDispatchToProps)(User);

export default CUser;