import {connect} from "react-redux";
import Login from "../../containers/basic/login";
import {user_set_action} from "../../actions/user";
import {createClient, getErrorMessage, setLogin} from "../../constants/utils";
import {SIGN_IN_CLIENT} from "../../share/mutation";
import {basic_set_open_snackbar} from "../../actions/basic";
import {TEXT_LIST} from "../../constants/index";
import {property_set_sidebar} from "../../actions/property";

let property_sidebar = {};
const mapStateToProps = (state) => {
  property_sidebar = state.property.property_sidebar;
  return {
    loading: state.basic.loading,
    property_sidebar: state.property.property_sidebar
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => {
    },
    onChangeAction: (action) => {
      dispatch(user_set_action(action));
    },
    onChangeCloseUser: () => {
      property_sidebar.type = 'property';
      dispatch(property_set_sidebar(property_sidebar));
    },
    onChangeSignInClient: async (history, {email, password}) => {
      const errors = checkData({email, password});
      if (errors.length) {
        return dispatch(basic_set_open_snackbar({type: 'error', message: errors.join('\n')}))
      } else {
        const client = createClient();
        client.request(SIGN_IN_CLIENT, {email, password}).then(async data => {
          const {token} = data.signInClient;
          await setLogin({token});
          window.location.reload();
        }).catch(e => {
          const error = getErrorMessage(e, dispatch);
          dispatch(basic_set_open_snackbar({type: 'error', message: error}));
        })
      }
    }
  }
};

const checkData = (info) => {
  let msg = [];
  if (!info.email) msg.push(`-「${TEXT_LIST.email_address}」${TEXT_LIST.is_required}`);
  if (!info.password) msg.push(`-「${TEXT_LIST.password}」${TEXT_LIST.is_required}`);
  
  return msg;
};


const CLogin = connect(mapStateToProps, mapDispatchToProps)(Login);

export default CLogin;