import {connect} from "react-redux";
import Invoices from '../../containers/pages/invoices'
import {invoice_set_info} from "../../actions/invoice";

const mapStateToProps = (state) => {
  return {
    invoice_info: state.invoice_info
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => {
      dispatch(invoice_set_info({a: 1, b: 2}))
    }
  }
};

const CInvoices = connect(mapStateToProps, mapDispatchToProps)(Invoices);


export default CInvoices;