import React from 'react';
import '../../assets/scss/bootstrap_table.scss';
import {withRouter} from "react-router-dom";
import Container from '@mui/material/Container';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {TEXT_LIST} from "../../constants/index";
import {addBreak} from "../../constants/utils";

class PropertyList extends React.Component {
  componentDidMount() {
    this.props.onInit();
  }
  
  render() {
    // return null;
    
    const {property_list, onChangeProperty, onChangeActionProperty,history} = this.props;
    let index = 0;
    
    return (
      <Container maxWidth="xl" style={{padding: '3rem'}}>
        <h4 className="p-2">物件一覧</h4>
        <div className="properties-table-scroll">
          <table className="table table-striped">
            <thead>
            <tr className="text-left">
              <th>#</th>
              <th>物件名</th>
              <th>説明</th>
              <th width="65">{TEXT_LIST.view}</th>
              <th width="65">計測</th>
            </tr>
            </thead>
            <tbody>
            {
              property_list.length ? property_list.map(property => {
                  index = index + 1;
                  return (
                    <tr className="text-left" key={property.id}>
                      <td>{index}</td>
                      <td>{property.name}</td>
                      <td>
                        <div dangerouslySetInnerHTML={{__html: addBreak(property.description)}}/>
                      </td>
                      <td>
                        <RemoveRedEyeIcon
                          className="cursor-pointer"
                          color="primary"
                          onClick={() => onChangeProperty(history, property.id)}
                        />
                      </td>
                      <td>
                        <RemoveRedEyeIcon
                          className="cursor-pointer"
                          color="primary"
                          onClick={() => onChangeActionProperty(history, property.id)}
                        />
                      </td>
                    </tr>
                  )
                }
              ) : null
            }
            </tbody>
          </table>
        </div>
      </Container>
    );
  }
}

export default withRouter(PropertyList);