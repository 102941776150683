import {connect} from "react-redux";
import ResetPassword from "../../containers/basic/reset-password";
import {user_set_action} from "../../actions/user";
import {TEXT_LIST} from "../../constants/index";
import {createClient, getErrorMessage, validateEmail, validatePassword} from "../../constants/utils";
import {basic_set_loading, basic_set_open_snackbar} from "../../actions/basic";
import {FORGOT_PASSWORD, RESET_PASSWORD} from "../../share/mutation";

const mapStateToProps = (state) => {
  return {
    loading: state.basic.loading,
    user_action: state.user.action
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => {},
    onChangeAction: (action) => {
      dispatch(user_set_action(action))
    },
    onChangeForgotPassword: (email) => {
      dispatch(basic_set_loading(true));
      const errors = checkData(email);
      if (errors.length) {
        dispatch(basic_set_loading(false));
        dispatch(basic_set_open_snackbar({type: 'error', message: errors.join('\n')}))
      } else {
        const client = createClient();
        client.request(FORGOT_PASSWORD, {email}).then(data => {
          dispatch(basic_set_loading(false));
          dispatch(basic_set_open_snackbar({type: 'success', message: 'メールが送信されました、メールをご確認よろしくお願い致します!'}));
          dispatch(user_set_action('reset_password'));
        }).catch(e => {
          const error = getErrorMessage(e);
          dispatch(basic_set_loading(false));
          dispatch(basic_set_open_snackbar({type: 'error', message: error}));
        })
      }
    },
    onChangeResetPassword: ({email, password, confirm_password, password_code}) => {
      const errors = checkDataResetPassword(email, password, confirm_password, password_code);
      if (errors.length) {
        dispatch(basic_set_open_snackbar({type: 'error', message: errors.join('\n')}))
      } else {
        const client = createClient();
        client.request(RESET_PASSWORD, {email, password, confirm_password, password_code}).then(data => {
          dispatch(user_set_action('sign_in'));
          dispatch(basic_set_open_snackbar({type: 'success', message: 'パスワードがリセットされました!、ログインしてください'}));
        }).catch(e => {
          const error = getErrorMessage(e);
          dispatch(basic_set_open_snackbar({type: 'error', message: error}));
        })
      }
    }
  }
};

const checkData = (email) => {
  let msg = [];
  if (!email) msg.push(`-「${TEXT_LIST.email_address}」${TEXT_LIST.is_required}`);
  
  return msg;
};

const checkDataResetPassword = (email, password, confirm_password, password_code) => {
  let msg = [];
  if (!email) msg.push(`-「${TEXT_LIST.email_address}」${TEXT_LIST.is_required}`);
  if (!password_code) msg.push(`-「Code」${TEXT_LIST.is_required}`);
  if (!password) msg.push(`-「${TEXT_LIST.password}」${TEXT_LIST.is_required}`);
  if (!confirm_password) msg.push(`-「${TEXT_LIST.confirm_password}」${TEXT_LIST.is_required}`);
  
  if (email && !validateEmail(email)) msg.push(`-「${TEXT_LIST.email_address}」の項目が必須です`);
  if (password) {
    if (validatePassword(password)) msg.push(`-${validatePassword(password)}`);
    if (password !== confirm_password) msg.push('-「パスワード」と確認用の「確認パスワード」が違います。改めてご入力下さい');
  }
  
  return msg;
};

const CResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

export default CResetPassword;