import React from 'react';
import {withRouter} from "react-router-dom";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CodeIcon from '@mui/icons-material/Code';
import KeyIcon from '@mui/icons-material/Key';
import {TEXT_LIST} from "../../constants/index";

const state_default = {email: '', password: '', confirm_password: '', password_code: '', timer: 120};

class ForgotPassword extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = state_default;
  }
  
  render() {
    const {user_action} = this.props;
    if (user_action === 'forgot_password') {
      return this.ForgotPassword();
    }
    else if (user_action === 'reset_password') {
      return this.ResetPassword();
    }
    else {
      return <p>No found action</p>
    }
  }
  
  ForgotPassword() {
    const {onChangeAction, onChangeForgotPassword} = this.props;
    return (
      <div className="user-action">
        <div className="parent">
          <div className="header">
            <h4 className="w-100">{TEXT_LIST.main}</h4>
          </div>
          <div className="body">
            <h5 className="center">パスワードリセット</h5>
            <br/>
            
            <label className="text-left">登録されているメールアドレスにパスワードのリセットの手順を送信します。<br/>
              メールアドレスを入力のうえ、送信してください。</label>
            <br/>
            <br/>
            
            <FormControl fullWidth variant="standard">
              <InputLabel>メールアドレス</InputLabel>
              <Input
                value={this.state.email || ''}
                onChange={(e) => this.setState({email: e.target.value})}
                startAdornment={<MailOutlineIcon position="start" fontSize="medium"/>}
              />
            </FormControl>
            
            <br/>
            <br/>
            
            <div className="footer text-main">
              <span className="font-weight-bold"
                    onClick={() => onChangeForgotPassword(this.state.email)}>{TEXT_LIST.send}</span>
              <br/> <br/>
              <span onClick={() => onChangeAction('sign_in')}>{TEXT_LIST.login} </span>
              |
              <span onClick={() => onChangeAction('register')}> {TEXT_LIST.register_mode}</span>
            </div>
          </div>
        
        </div>
      </div>
    )
  }
  
  ResetPassword() {
    const {onChangeAction, onChangeResetPassword} = this.props;
    return (
      <div className="user-action">
        <div className="parent">
          <div className="header">
            <h4 className="w-100">{TEXT_LIST.main}</h4>
          </div>
          <div className="body">
            <h5 className="center">パスワードリセット</h5>
            <br/>
            <FormControl fullWidth variant="standard" disabled>
              <InputLabel>メールアドレス</InputLabel>
              <Input
                value={this.state.email || ''}
                onChange={(e) => this.setState({email: e.target.value})}
                startAdornment={<MailOutlineIcon position="start" fontSize="medium"/>}
              />
            </FormControl>
            <br/><br/>
            
            <FormControl fullWidth variant="standard">
              <InputLabel>Code</InputLabel>
              <Input
                value={this.state.password_code || ''}
                onChange={(e) => this.setState({password_code: e.target.value})}
                startAdornment={<CodeIcon position="start" fontSize="medium"/>}
              />
            </FormControl>
            <br/><br/>
            
            <FormControl fullWidth variant="standard">
              <InputLabel>{TEXT_LIST.password}</InputLabel>
              <Input
                type={'password'}
                value={this.state.password || ''}
                onChange={(e) => this.setState({password: e.target.value})}
                startAdornment={<KeyIcon position="start" fontSize="medium"/>}
              />
            </FormControl>
            <br/><br/>
            
            <FormControl fullWidth variant="standard">
              <InputLabel>{TEXT_LIST.confirm_password}</InputLabel>
              <Input
                type={'password'}
                value={this.state.confirm_password || ''}
                onChange={(e) => this.setState({confirm_password: e.target.value})}
                startAdornment={<KeyIcon position="start" fontSize="medium"/>}
              />
            </FormControl>
            <br/><br/>
            
            <div className="footer text-main">
              <span className="font-weight-bold"
                    onClick={() => onChangeResetPassword(this.state)}>{TEXT_LIST.send}</span>
              <br/> <br/>
              <span onClick={() => onChangeAction('sign_in')}>{TEXT_LIST.login} </span>
              |
              <span onClick={() => {
                onChangeAction('forgot_password');
                this.setState(state_default)
              }}> {TEXT_LIST.forgot_password}</span>
            </div>
          </div>
        
        </div>
      </div>
    )
  }
}

export default withRouter(ForgotPassword);