import {USER_INFO} from "../../constants/store";

const info = (state = {}, action) => {
  switch (action.type) {
    case USER_INFO:
      return Object.assign({}, action.payload);
    default:
      return state;
  }
};

export default info;