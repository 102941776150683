import {USER_ACTION} from "../../constants/store";

const action = (state = '', action) => {
  switch (action.type) {
    case USER_ACTION:
      return action.payload;
    default:
      return state;
  }
};

export default action;