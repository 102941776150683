import {combineReducers} from "redux";
import action from "./action";
import info from "./info";

const user = combineReducers({
  action,
  info
});

export default user;
