import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class Snackbars extends React.Component {
  render() {
    const {snackbar, handleClose} = this.props;
    let {open, type, message} = snackbar;
    
    if (!open) return null;
    
    type = ['error','warning','info','success'].includes(type) ? type : 'success';
    
    setTimeout(() => {
      handleClose();
    }, 8000);
    
    return (
      <div>
        <Snackbar
          open={open}
          autoHideDuration={8000}
          message={message}
          style={{whiteSpace: 'pre-line', background: 'white'}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <Alert variant="outlined" onClose={handleClose} severity={type}>
            { message }
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default Snackbars;
